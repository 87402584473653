<template>
  <div class="container">
    <iframe
      ref="myIframe"
      class="child"
      :src="url"
      frameborder="0"
      :style="{width: width, height:height}"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      url: 'static/termOfService.html',
      height: (window.innerHeight) + 'px',
      width: '100%'
    }
  }
}
</script>

<style lang="scss" scoped>
.agreement_body {
  padding: 30px;
  font-size: 16px;
  color: #695a5a;
  line-height: 30px;
  p {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 20px;
    }
  strong {
    font-weight: 600;
    font-size: 18px;
  }
  ul {
    list-style: inherit;
    margin-bottom: 20px;
  }
  .ul2 {
    list-style: none;
    margin-bottom: 20px;
  }
}
</style>
